import React, { useEffect, useState } from "react";
// import i18n from './i18n';
import AppRoutes from "./App.routing";
import "./App.scss";
import { withRouter } from "react-router";
import HeaderSidebarWrapper from "./components/HeaderSidebarWrapper/HeaderSidebarWrapper";
import Toast from "./components/Toast/Toast";
import ToastRight from "./components/ToastRight/ToastRight";
// import GoogleAutocompleteService from './utils/googleAutocompleteService';
// import { loadThirdPartyScript } from './utils/loadThirdPartyScript';
import appEndpoints from "./App.endpoints";
import useApiService from "./services/api.service";
import { useAppContext } from "./AppContext/App.context";
import * as actions from "./AppContext/actions/actions";
import PageLoader from "./components/pageLoader/pageLoader";
import useLocalStorage from "./utils/localStorage";
import { Socket } from "./utils/socket";
import setVariables from "./utils/setVariables";

import { useTranslation } from "react-i18next";
import Header from "./components/Header/Header";

if (process.env.NODE_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}
const exclusionArray = [
  "/login",
  "/register",
  "/step1",
  "/step2",
  "/resetpassword",
  "/signup",
  "/checkotp",
];
function getFaviconEl() {
  return document.getElementById("favicon");
}
function App({ location }: any) {
  const [appData, dispatch]: any = useAppContext();
  const { t, i18n } = useTranslation();
  // let [, setShowChatDropdown] = useState(false);
  const loginRequired = true;

  const ApiService: any = useApiService();
  let isHeaderSidemenuVisible: any = !exclusionArray.includes(
    location.pathname
  );
  const [test, setTest] = useState(!!useLocalStorage.getItem("isSet"));
  useEffect(() => {
    setTest(true);
    if (window.location.pathname === "/login") {
      checkCustomData();
    }
    const favicon: any = getFaviconEl();
    if (useLocalStorage.getItem("CustomData")) {
      favicon.href =
        useLocalStorage.getItem("CustomData").navigation_icon ||
        window.location.origin + "/favicon1.svg";
    }
    // useLocalStorage.setItem("login_required",true)
    // if(exclusionArray.includes("/checkotp")){
    //   isHeaderSidemenuVisible=false
    // }
    const loginViaAccessToken = async () => {
      const data = {
        access_token: appData.loginData.access_token,
      };
      const res = await ApiService.post(appEndpoints.loginViaAccessToken(data));
      dispatch(actions.loginViaAccessToken(res.data));
    };

    return () => {
      // disconnectSocket();
    };

    // eslint-disable-next-line
  }, []);

  const changeHandler = (ev: any) => {
    i18n.changeLanguage(ev);
  };
  useEffect(() => {});
  let customData: any = {};
  const checkCustomData = () => {
    const request: any = {
      url: window.location.host==="booking.networkon.tk"?"https://demo.networkon.tk/":"https://"+window.location.host+"/",
    };
    return ApiService.get(appEndpoints.formSettings(request))
      .then(async (res: any) => {
        console.log(res);
        if (res.message === "success") {
          useLocalStorage.setItem("CustomData", res.data[0]);

          useLocalStorage.setItem(
            "referenceId",
            res.data[0].customer_reference_id
          );
          customData = useLocalStorage.getItem("CustomData");
        }
        console.log(customData);
        return true;
      })
      .catch((err: any) => {});
  };
  return (
    <div className={isHeaderSidemenuVisible ? "app" : ""}>
      {appData.onLoaded?.showLoader ? <PageLoader /> : null}
      {window.location.href.indexOf("checkotp") < 0 ? (
        isHeaderSidemenuVisible ? (
          <HeaderSidebarWrapper></HeaderSidebarWrapper>
        ) : null
      ) : (
        ""
      )}

      <div className={isHeaderSidemenuVisible ? "app__outer" : ""}>
        <AppRoutes></AppRoutes>
      </div>
      <Toast></Toast>
    </div>
  );
}

App.whyDidYouRender = true;

export default withRouter(App);

import React, { useState, forwardRef, Suspense, useRef } from "react";
import CalendarTodayIcon from "@material-ui/icons/CalendarTodayOutlined";
import DateTimeFormatter from "../../utils/DateFormatter";
import headerDropdownService from "../../services/headerDropdown.service";
import useToast from "../Toast/hooks/useToast";
import DateRangePopup from "./DatRangePopup";
import classes from "../Header/Header.module.scss";

const DateRangePopupHeader = forwardRef((props: any, ref: any) => {
  const dateRangeRef: any = useRef();
  const toaster = useToast();
  let startDT: any = new Date();
  startDT = startDT.setDate(startDT.getDate() - 6);
  startDT = DateTimeFormatter.changeOnlyFormatInSameTimeZone(
    startDT,
    "YYYY-MM-DD"
  );
  let currentEnDate: any = new Date();
  currentEnDate = DateTimeFormatter.changeOnlyFormatInSameTimeZone(
    currentEnDate,
    "YYYY-MM-DD"
  );
  const [dateRange, setDateRange]: any = useState({
    startDate: startDT,
    endDate: currentEnDate,
  });
  const onChangeDateRange = (range: any) => {
    let RangeLimit: any = Math.round(
      (range[0]["endDate"] - range[0]["startDate"]) / (1000 * 60 * 60 * 24)
    );
    if (RangeLimit > 6) {
      toaster.addToast({
        message: "Please select range for less than 7 days",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    let startDate = range[0]["startDate"];
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate = DateTimeFormatter.changeOnlyFormatInSameTimeZone(
      startDate,
      "YYYY-MM-DD"
    );
    let endDate: any = range[0]["endDate"];
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endDate = DateTimeFormatter.changeOnlyFormatInSameTimeZone(
      endDate,
      "YYYY-MM-DD"
    );
    setDateRange({ startDate: startDate, endDate: endDate });
    const dropdownData = {
      type: "dateRange",
      data: { date: range },
    };
    headerDropdownService.emit(dropdownData);
  };
  const dateRangeClick = (event: any) => {
    return dateRangeRef.current.handleOpen(event);
  };
  return (
    <div>
      <div className={classes.pickerIcon}>
        <CalendarTodayIcon
          className={classes["datepickerIcon"]}
          style={{ marginBottom: "0px" }}
          //style={{ color: themeColor.iconsColor }}
        />
        <div
          style={{ color: "black", cursor: "pointer" }}
          onClick={dateRangeClick}
        >
          {dateRange.startDate === dateRange.endDate
            ? dateRange.startDate
            : `${dateRange.startDate} - ${dateRange.endDate}`}
        </div>
        <Suspense fallback={<h3>Loading&hellip;</h3>}>
          <DateRangePopup
            ref={dateRangeRef}
            onChangeDateRange={onChangeDateRange}
          ></DateRangePopup>
        </Suspense>
      </div>
    </div>
  );
});

export default DateRangePopupHeader;

// const API_BASE_URL="http://54.225.194.218:3001/";

const CommonEndpoints = {
  getTeamList(reqData: any) {
    return {
      url: "main/team/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getTagsList(reqData: any) {
    return {
      // API_BASE_URL:API_BASE_URL,
      url: "main/tag/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getResourceList(reqData: any) {
    return {
      //  API_BASE_URL:API_BASE_URL,
      url: "resource/resource/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getJobDetailsByJobId(reqData: any) {
    return {
      url: "customer/job/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  updateJobDetails(reqData: any) {
    return {
      url: "main/job/update",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  deleteJob(reqData: any) {
    return {
      url: "customer/job/remove",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getJobList(reqData: any) {
    return {
      //  API_BASE_URL:API_BASE_URL,
      url: "main/job/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getTaskDetails(reqData: any) {
    return {
      //  API_BASE_URL:API_BASE_URL,
      url: "customer/job/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getAutoAllocation(reqData: any) {
    return {
      url: "assignment/assignmentSetting/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getRolesList(reqData: any) {
    return {
      url: "roles/roles/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getUserProfileDetails(reqData: any) {
    return {
      url: "auth/user/get",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },

  uploadImage: (data: object) => ({
    url: "main/file/upload",
    data,
  }),
};

export default CommonEndpoints;

import React, {
  useState,
  Fragment,
  useImperativeHandle,
  forwardRef,
  Suspense,
  useRef,
} from "react";
// import googleMutant from "../../utils/leaflet/Leaflet.GoogleMutant"
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import DateTimeFormatter from "../../utils/DateFormatter";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    maxWidth: "400px",
    maxHeight: "400px",
    outline: "none",
    overflow: "auto",
  },
  btn: {
    margin: theme.spacing(2),
  },
  dropdown: {
    textAlign: "left",
  },
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  jobDetail: {
    padding: "10px 0px",
  },
  exceptionIcon: {
    "&:last-child": {
      cursor: "pointer",
    },
  },
}));
const DateRangePopup = forwardRef((props: any, ref: any) => {
  //const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let startDT: any = new Date();
  startDT = startDT.setDate(startDT.getDate() - 6);
  let [rangeDataChanged, setRangeDataChangedBool]: any = useState(0);
  const [RangeData, setRangeData]: any = useState([
    {
      startDate: startDT,
      endDate: new Date(),
      key: "value",
    },
  ]);
  const id = open ? "simple-popover" : undefined;

  useImperativeHandle(ref, () => ({
    handleOpen(event: any) {
      console.log(event);
      setRangeDataChangedBool(0);
      setAnchorEl(event.target);
    },
  }));

  const onCalendarDateChange = (value: any) => {
    console.log(value);
    rangeDataChanged = true;
    // const endDateObj = { ...value };
    // let secondDate: any = new Date(endDateObj.value.endDate);
    // secondDate.setHours(23);
    // secondDate.setMinutes(59);
    // secondDate.setSeconds(59);
    // secondDate = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
    //   secondDate
    // );
    // const startDateObj = { ...value };
    // let firstDate: any = new Date(startDateObj.value.startDate);
    // firstDate.setHours(0);
    // firstDate.setMinutes(0);
    // firstDate.setSeconds(0);
    // firstDate = DateTimeFormatter.convertDateTimeToSpecificFormatInUtc(
    //   firstDate
    // );
    setRangeData([value.value]);
    setRangeDataChangedBool(1);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (rangeDataChanged) props.onChangeDateRange(RangeData);
  };
  return (
    <Fragment>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.header}>
              <DateRange
                editableDateInputs={true}
                onChange={onCalendarDateChange}
                moveRangeOnFirstSelection={false}
                ranges={RangeData}
              />
            </div>
          </div>
        </Fade>
      </Popover>
    </Fragment>
  );
});

export default DateRangePopup;

import moment from "moment";
export const defaultDateTimeFormat = "YYYY-MM-DD HH:mm";
export const defaultTimeForamt = "HH:mm";
export const defaultDateFormat = "YYYY-MM-DD";
const DateTimeFormatter = {
  convertDateTimeToSpecificFormatInUtc(dateTime: Date, utcOffset?: string) {
    return moment(dateTime).utc().format(defaultDateTimeFormat);
  },
  convertDateTimeToSpecificFormat(
    dateTime: string,
    utcOffset?: string,
    format = defaultDateTimeFormat
  ) {
    if (utcOffset) {
      return moment.utc(dateTime).utcOffset(utcOffset).format(format);
    }
    return moment(dateTime).utc().format(format);
  },
  changeOnlyFormatInSameTimeZone(dateTime: string, format: string) {
    return moment(dateTime).format(format);
  },
  getTimeFromDateTimeInSameTimeZone(
    dateTime: Date,
    format = defaultTimeForamt
  ) {
    return moment(dateTime).utc().format(format);
  },
  getValueInMilliSeconds(dateTime: Date) {
    console.log(moment(dateTime).utc());
    console.log(moment(dateTime).utc().format(defaultDateTimeFormat));
    return moment.tz(dateTime, "Asia/Kolkata").valueOf();
  },
};
export default DateTimeFormatter;

import React, { createContext, useReducer, useContext } from "react";
import useLocalStorage from "../utils/localStorage";
const AppContext = createContext({});
const localStorage = useLocalStorage;

const initialState = {
  loginReq: !!(
    localStorage.getItem("loginReq") || localStorage.getItem("loginReq")
  ),
  loginData: localStorage.getItem("loginData") || {},
  onLoaded: { showLoader: false },
};
const AppContextProvider = ({ reducer, children }: any) => {
  return (
    <AppContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);

export { AppContext, AppContextProvider, useAppContext };

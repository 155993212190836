import React, { useState, Fragment, useEffect } from "react";
import clsx from "clsx";
import Header from "../Header/Header";
import "./HeaderSidebarWrapper.module.scss";
import icon_to_close_sidebar from "../../assets/images/icon_to_close_sidebar.svg";
import icon_to_open_sidebar from "../../assets/images/open-side-menu.svg";
import smallLogo from "../../assets/images/inner-short-logo.jpeg";
import largeLogo from "../../assets/images/logo.svg";
import TimelineIcon from "@material-ui/icons/Timeline";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import * as actions from "../../AppContext/actions/actions";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

import AutorenewIcon from "@material-ui/icons/Autorenew";
import MapIcon from "@material-ui/icons/Map";
import { useHistory, useLocation } from "react-router";
import headerDropdownService from "../../services/headerDropdown.service";

import VpnKeyIcon from "@material-ui/icons/VpnKey";
import useLocalStorage from "../../utils/localStorage";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../AppContext/App.context";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    boxShadow: "0 0 5px 0 #e5e9f2",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "#ffffff",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    width: "50px",
    height: "50px",
  },
  fakeMenuButton: {
    "&:hover, &:focus": {
      background: "none",
    },
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  largeLogo: {
    maxWidth: "200px",
    maxHeight: "50px",
    // padding: "10px",
  },
  smallLogo: {
    width: "50px",
    height: "50px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  selected: {
    // height: "70px",
    "&.Mui-selected": {
      color: "#fff",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      width: "calc(100% + 15px)",
      background: "#3c7bf6",
      "& svg": {
        color: "#fff",
      },
      "&:hover, &:focus": {
        background: "#3c7bf6",
        color: "#fff",
      },
    },
  },
  floatingIcon: {
    position: "absolute",
    bottom: "25px",
  },
  list: {
    height: "calc(100vh - 160px)",
  },
  subCategoryIcon: {
    marginLeft: "10px",
  },
  subCategoryList: {
    background: "#e3e3e34a",
  },
}));
// const ListItem1 = withStyles({
//   root: {
//     "&$selected": {
//       backgroundColor: "#3c7bf6",
//       color: "#fff"
//     },
//     "&$selected:hover": {
//       backgroundColor: "#3c7bf6",
//       color: "#fff"
//     }
//   },
//   selected: {}
// })(MuiListItem);
const HeaderSidebarWrapper = (props: any) => {
  const classes = useStyles();

  const { t, i18n } = useTranslation(["Sidebar"]);

  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [settingsListOpen, setSettingsListOpen] = useState(false);
  const [analyticsListOpen, setAnalyticsListOpen] = useState(false);
  const [appData, dispatch]: any = useAppContext();

  const location = useLocation();
  const history = useHistory();
  const Permission: any = useLocalStorage.getItem("Permission");
  let APIKey: any = true;
  let PermissionsArr: any = Permission ? Object.values(Permission) : [];
  if (PermissionsArr.length) {
    PermissionsArr.map((val: any) => {
      if (!val) {
        APIKey = false;
        return;
      }
    });
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const goToHome = () => {};
  const openCretaeTaskFromSidebar = () => {
    const dropdownData = {
      type: "clickedOnCreateTask",
      data: { clicked: true },
    };
    headerDropdownService.emit(dropdownData);
  };
  useEffect(() => {
    let headerSubscription = headerDropdownService.subscribe(eventFromService);
    console.log(headerSubscription);
    return () => {
      headerSubscription.unsubscribe();
    };
  }, []);
  const [enpType, setEnpType]: any = useState();
  const eventFromService = (eventData: any) => {
    console.log(eventData.type);
    switch (eventData.type) {
      case "earning":
        setEnpType(0);
        break;
      case "pricing":
        setEnpType(1);
        break;
      default:
        break;
    }
  };
  const logoutFromDashboard = () => {
    const customSettings = useLocalStorage.getItem("CustomData");

    localStorage.clear();
    useLocalStorage.setItem("CustomData", customSettings);
    // dispatch(actions.onLogout());
    window.location.href = "/login";
  };
  const customData = useLocalStorage.getItem("CustomData");
  return (
    <Fragment>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={goToHome}
            edge="start"
            className={clsx(classes.menuButton, classes.fakeMenuButton, {
              [classes.hide]: open,
            })}
          >
            <img
              className={classes.smallLogo}
              src={
                customData && customData.navigation_icon
                  ? customData.navigation_icon
                  : smallLogo
              }
            />
            {/* <img src={largeLogo} className={classes.largeLogo} /> */}
          </IconButton>
          <Header></Header>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {/* <IconButton onClick={handleDrawerClose}>

            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton> */}
          <img
            src={
              customData && customData.login_icon
                ? customData.login_icon
                : largeLogo
            }
            className={classes.largeLogo}
          />
          <img src={icon_to_close_sidebar} onClick={handleDrawerClose} />
        </div>

        <List className={classes.list}>
          <ListItem>
            <ListItemIcon>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <img src={icon_to_open_sidebar} />
              </IconButton>
            </ListItemIcon>
          </ListItem>

          <ListItem
            onClick={() => {
              history.push("/jobs/list");
            }}
            selected={location.pathname.includes("/jobs/list")}
            className={classes.selected}
            button
            key="Jobs"
          >
            <ListItemIcon>
              <AssignmentOutlinedIcon color={"action"}></AssignmentOutlinedIcon>
              {/* <img src={task_sidebar_icon} alt="task logo" /> */}
            </ListItemIcon>
            {open ? <ListItemText primary={t("Sidebar:Jobs")} /> : ""}
          </ListItem>

          <ListItem
            onClick={() => {
              history.push("/jobs/create");
            }}
            selected={location.pathname.includes("/jobs/create")}
            className={classes.selected}
            button
            key="Jobs"
          >
            <ListItemIcon>
              <AddCircleOutlineIcon color={"action"} />
            </ListItemIcon>
            {open ? <ListItemText primary={t("Sidebar:Create Job")} /> : ""}
          </ListItem>
          <ListItem
            onClick={() => {
              history.push("/contacts");
            }}
            selected={location.pathname.includes("/contacts")}
            className={classes.selected}
            button
            key="Jobs"
          >
            <ListItemIcon>
              <AccountBoxIcon color={"action"}></AccountBoxIcon>
              {/* <img src={task_sidebar_icon} alt="task logo" /> */}
            </ListItemIcon>
            {open ? <ListItemText primary={t("Sidebar:Contacts")} /> : ""}
          </ListItem>
          <ListItem
            onClick={() => {
              history.push("/analytics");
            }}
            selected={location.pathname.includes("/analytics")}
            className={classes.selected}
            button
            key="Analytics"
          >
            <ListItemIcon>
              <TimelineIcon color={"action"} />
            </ListItemIcon>
            {open ? <ListItemText primary={t("Sidebar:Analytics")} /> : ""}
          </ListItem>

          {/* <ListItem
            onClick={() => {
              logoutFromDashboard();
            }}
            selected={location.pathname.includes("/logout")}
            className={classes.selected}
            button
            key="Jobs"
          >
            <ListItemIcon>
              <ExitToAppIcon color={"action"} />

            </ListItemIcon>
            {open ? <ListItemText primary={t("Sidebar:Logout")} /> : ""}
          </ListItem> */}
        </List>
      </Drawer>
      {/* <ListItem className={classes.selected} button key="Analytics">
            <ListItemIcon>
              <img src={analytics_sidebar_icon} alt="task logo" />
            </ListItemIcon>
            {open ? <ListItemText primary="Analytics" /> : ""}
          </ListItem> */}

      {/* <Header></Header>
      <SideMenu></SideMenu> */}
    </Fragment>
  );
};
export default HeaderSidebarWrapper;

// {TabPermission && TabPermission[RolePermissions.Read_APIKey] ? (
//   <ListItem
//   className={classes.selected}
//   button
//   selected={location.pathname.includes("/settings/apikey")}
//   onClick={() => {
//     history.push("/settings/apikey");
//   }}
// >
//   <ListItemIcon>
//     <VpnKeyIcon
//       color={"action"}
//     ></VpnKeyIcon>
//     {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
//   </ListItemIcon>
//   {open ? <ListItemText primary="API Key" /> : ""}
// </ListItem>
// ): ( "" )}

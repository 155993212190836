import React, { useState, Fragment, useRef } from "react";
import "./Signup.scss";
import { useForm } from "react-hook-form";

import Input from "../../components/Input/Input";
import { ValidationService } from "../../utils/Validation";
import Btn from "../../components/Button/Button";
import Phone from "../../components/phone-picker/phone-picker";
import useApiService from "../../services/api.service";
import { useTranslation } from "react-i18next";
import useLoader from "../../hooks/useLoader";
import SigninEndpoints from "./Signup.endpoints";
import useToast from "../../components/Toast/hooks/useToast";
import { useHistory } from "react-router-dom";
import useLocalStorage from "../../utils/localStorage";
import { useAppContext } from "../../AppContext/App.context";

const Register = () => {
  const { t, i18n } = useTranslation(["Signup"]);
  const { register, handleSubmit, errors } = useForm();
  const loader: any = useLoader();
  const ApiService = useApiService();
  const toaster = useToast();
  const history = useHistory();
  const [appData]: any = useAppContext();

  // const [showPassword, setShowPassword] = useState(false);
  // const handleClickShowPassword = () => {
  //   setShowPassword(!showPassword);
  // };

  const dialCode: any = useRef<any>();
  const countryCode: any = useRef();
  const [preSavedCountryCode, updatePreSavedCountryCode]: any = useState("91");
  const obj = {
    name: {
      required: ValidationService.requiredValidator(),
    },
    email: {
      required: ValidationService.requiredValidator(),
      pattern: ValidationService.emailValidator(),
    },
    phone: {
      required: ValidationService.requiredValidator(),
    },
  };

  const fetchDialCode = (dialcode: any, countrycode: any) => {
    dialCode.current = "+" + dialcode;
    countryCode.current = countrycode.toUpperCase();
  };

  // Submit Form
  const submitSignupForm = (data: any) => {
    if (!data.name.trim()) {
      toaster.addToast({
        message: "Name is invalid",
        timeout: 3000,
        type: "error",
      });
      return;
    }
    const request: any = {
      country_code: dialCode.current,
      phone_number: data.phone,
      app_version: "100.1",
      device_details: "1",
      name: data.name.trim(),
      email: data.email,
    };
    const headers = {
      reference_id: useLocalStorage.getItem("referenceId"),
    };
    const reqData = {
      reqBody: request,
      headers: headers,
    };
    loader.showLoader();
    return ApiService.post(SigninEndpoints.signin(reqData))
      .then(async (res: any) => {
        loader.hideLoader();
        console.log(res);

        if (res.message === "success") {
          // useLocalStorage.setItem("loginData", res.data);
          // useLocalStorage.setItem("userAccessToken", res.data.access_token);
          if (appData.loginData) {
            history.push(`/checkotp/${dialCode.current}/${data.phone}`);
          }
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  return (
    <div className="Signup">
      <div className="Signup__header"></div>
      <div className="Signup__body">
        <div className="">
          <Fragment>
            <div className="Signup__fp-title">{t("Signup:Sign Up")}</div>
            <div className="my-3">
              {t("Signup:Enter your details to continue")}
            </div>
          </Fragment>
        </div>

        <div className="Signup__card">
          <Fragment>
            <form onSubmit={handleSubmit(submitSignupForm)}>
              <Input
                type="text"
                id="name"
                name="name"
                refValue={register(obj.name)}
                errors={errors}
                placeholder="Enter Your Name"
                label={t("Common:Name") + "*"}
              ></Input>

              <Input
                type="email"
                id="email"
                name="email"
                refValue={register(obj.email)}
                errors={errors}
                placeholder="Enter Your Email Address"
                label={t("Common:Email Address") + "*"}
              ></Input>
              <Phone
                hideLabel={true}
                showPhone={true}
                setDialCode={fetchDialCode}
                name="phone"
                label={t("Common:Mobile Number")}
                placeholder="Mobile Number*"
                required={true}
                defaultPhone={preSavedCountryCode}
                errors={errors}
                refValue={register(obj.phone)}
              ></Phone>
              <Btn
                type={"Submit"}
                btnText={t("Signup:Sign Up")}
                className={"Signup__btn mt-3"}
              >
                {""}
              </Btn>
              <div
                className="Signup__login my-2"
                onClick={() => history.push("/login")}
              >
                {t("Signup: Already have an Account?")}
              </div>
            </form>
          </Fragment>
        </div>
      </div>
    </div>
  );
};

export default Register;
